import React, { useState, useEffect } from "react"; 
import axios from "axios";
import MapGraph from "../components/location/MapGraph";
import "../css/GraphLocationsdata.css";

const InfoBox = ({ title, value }) => (
  <div className="info-box">
    <h4 className="info-title">{title}</h4>
    <p className="info-value">{value}</p>
  </div>
);

const GraphLocationsdata = () => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalParticipants, setTotalParticipants] = useState(0);

  useEffect(() => {
    fetchData();
    fetchParticipantData();
  }, []);

  const fetchData = async () => {
    try {
      // const response = await axios.get("http://89.116.20.162:8081/api/locations/completed/all");
      const response = await axios.get("http://89.116.20.162:8081/api/locations/completed/all");

      console.log("Fetched Locations Data:", response.data); // Log fetched data
      setLocations(response.data);
      setLoading(false);
    } catch (error) {
      setError("Error fetching data: " + error.message);
      setLoading(false);
    }
  };

  const fetchParticipantData = async () => {
    try {
      // const response = await axios.get('http://localhost:8081/aggregatedCounts');
      const response = await axios.get('http://89.116.20.162:8081/aggregatedCounts');

      const data = response.data;

      const total = (data.age6to8Count || 0) + (data.age9to13Count || 0) +
                    (data.age14to15Count || 0) + (data.age16to17Count || 0) +
                    (data.age18Count || 0) + (data.age18plusCount || 0);
      setTotalParticipants(total);
    } catch (error) {
      console.error('Error fetching aggregated counts:', error);
    }
  };

  const processDataForGraphs = () => {
    const uniqueCategories = [...new Set(locations.map((loc) => loc.category))];
    const completedCounts = uniqueCategories.map((category) => {
      const filtered = locations.filter(
        (loc) => loc.category === category && loc.status === "Completed"
      );
      return { category, count: filtered.length };
    });
    return completedCounts;
  };

  if (loading) return <div className="loader">Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const graphData = processDataForGraphs();

  return (
    <div className="graph-locations-container">
      <div className="text-content">
        <h3 className="heading">
          SAFE IAQ (Study and Assessment for Formulating Environmental Best Practices for Indoor Air Quality)
        </h3>
        {/* <p className="description">
          A comprehensive observational study in the field of indoor air quality evaluation through the project entitled
        </p> */}
        <p className="project-details">
          'Evaluation of Air Quality in Different Indoor Environments in the Mumbai Metropolitan Region (MMR), as Representative of Maharashtra State with Special Reference to Non-Attainment Areas and Severely Impacted Areas, and Formulation of Best Practices for Enhanced Indoor Air Quality'
        </p>
      </div>

      <div className="graphs-container">
      <section className="map-section">
          <div className="map-wrapper">
            <MapGraph locations={locations} />
          </div>
        </section>
        <div className="info-boxes-container">
          
          <InfoBox title="Health Assesments" value={`Total: ${totalParticipants}`} />
          {graphData.map((data, index) => (
            <InfoBox key={index} title={` ${data.category}`} value={`Completed: ${data.count}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GraphLocationsdata;
