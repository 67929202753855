import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/gallery/general/logo.png";
import "../css/Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faEnvelope,
  faQuestionCircle,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { FaBlog } from "react-icons/fa";

const Navigation = ({ setRegion, setSeason, setActiveAnalysisTab }) => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [questionnaireOpen, setQuestionnaireOpen] = useState(false);
  const [isIaqTabSelected, setIaqTabSelected] = useState(false);
  const [region, setRegionState] = useState("");
  const [season, setSeasonState] = useState("");
  const [showFilters, setShowFilters] = useState(true);

  const navigate = useNavigate();
  const iaqRef = useRef(null);

  const handleIaqTabClick = () => {
    setIaqTabSelected(!isIaqTabSelected);
    setActiveAnalysisTab(null);
    setShowFilters(!isIaqTabSelected);
  };

  const handleRegionChange = (event) => {
    const selectedRegion = event.target.value;
    setRegionState(selectedRegion);
    setRegion(selectedRegion);
  };
  
  const handleSeasonChange = (event) => {
    const selectedSeason = event.target.value;
    setSeasonState(selectedSeason);
    setSeason(selectedSeason);
  
    // Close everything if both region and season are selected
    if (region && selectedSeason) {
      navigate(`/trendanalysis?region=${region}&season=${selectedSeason}`);
      setIaqTabSelected(false);
      setMenuOpen(false); // Close the hamburger menu
      setAboutOpen(false);
      setQuestionnaireOpen(false);
    }
  };
    // Close Menu After Selection
    const handleLinkClick = () => {
      setTimeout(() => {
        setMenuOpen(false);
        setAboutOpen(false);
        setQuestionnaireOpen(false);
      }, 150); // Smooth closing with 150ms delay
    };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (iaqRef.current && !iaqRef.current.contains(event.target)) {
        setIaqTabSelected(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleAbout = () => setAboutOpen(!aboutOpen);
  const toggleQuestionnaire = () => setQuestionnaireOpen(!questionnaireOpen);

  // const handleLinkClick = () => {
  //   if (window.innerWidth <= 768) {
  //     setMenuOpen(false);
  //     setAboutOpen(false);
  //     setQuestionnaireOpen(false);
  //   }
  // };

  // Rest of your JSX remains the same
  return (
    <nav className={`custom-navigation ${scrolled ? "scrolled" : ""}`}>
      <div className="custom-navigation-container">
        <Link to="/" className="custom-navigation-logo" onClick={handleLinkClick}>
          <img src={logo} alt="Logo" />
        </Link>
        <div className="custom-navigation-toggle" onClick={toggleMenu}>
          <span className="custom-bar"></span>
          <span className="custom-bar"></span>
          <span className="custom-bar"></span>
        </div>
        <ul className={`custom-navigation-menu ${menuOpen ? "show" : ""}`}>
          <li className="custom-navigation-item">
            <Link to="/" className="custom-navigation-link" onClick={handleLinkClick}>Home</Link>
          </li>

          <li className={`custom-navigation-item custom-dropdown ${aboutOpen ? "open" : ""}`}
              onMouseEnter={() => setAboutOpen(true)}
              onMouseLeave={() => setAboutOpen(false)}>
            <span className="custom-navigation-link custom-dropdown-toggle" onClick={toggleAbout}>
              About
            </span>
            <ul className="custom-dropdown-menu">
              <li>
                <Link to="/team" className="custom-dropdown-link" onClick={handleLinkClick}>
                  <FontAwesomeIcon icon={faUsers} /> Team
                </Link>
              </li>
              <li>
                <Link to="/gallery" className="custom-dropdown-link" onClick={handleLinkClick}>
                  <FontAwesomeIcon icon={faImage} /> Gallery
                </Link>
              </li>
              <li>
                <Link to="/blog" className="custom-dropdown-link" onClick={handleLinkClick}>
                  <FaBlog /> Blog
                </Link>
              </li>
            </ul>
          </li>

          <li className={`custom-navigation-item custom-dropdown ${questionnaireOpen ? "open" : ""}`}
              onMouseEnter={() => setQuestionnaireOpen(true)}
              onMouseLeave={() => setQuestionnaireOpen(false)}>
            <span className="custom-navigation-link custom-dropdown-toggle" onClick={toggleQuestionnaire}>
              <FontAwesomeIcon icon={faQuestionCircle} /> Questionnaire
            </span>
            <ul className="custom-dropdown-menu">
              <li><Link to="/questionnaire/adult" className="custom-dropdown-link" onClick={handleLinkClick}>Adult</Link></li>
              <li><Link to="/questionnaire/small" className="custom-dropdown-link" onClick={handleLinkClick}>Small</Link></li>
              <li><Link to="/questionnaire/mid" className="custom-dropdown-link" onClick={handleLinkClick}>Mid</Link></li>
              <li><Link to="/questionnaire/school" className="custom-dropdown-link" onClick={handleLinkClick}>School</Link></li>
              <li><Link to="/questionnaire/restaurant" className="custom-dropdown-link" onClick={handleLinkClick}>Restaurant</Link></li>
              <li><Link to="/questionnaire/residential" className="custom-dropdown-link" onClick={handleLinkClick}>Residential</Link></li>
              <li><Link to="/questionnaire/office" className="custom-dropdown-link" onClick={handleLinkClick}>Office Building</Link></li>
              <li><Link to="/questionnaire/mall" className="custom-dropdown-link" onClick={handleLinkClick}>Malls</Link></li>
              <li><Link to="/questionnaire/cinema" className="custom-dropdown-link" onClick={handleLinkClick}>Cinema Hall</Link></li>
            </ul>
          </li>

          <li className="custom-navigation-item">
            <Link to="/statistics" className="custom-navigation-link" onClick={handleLinkClick}>Statistics</Link>
          </li>

          <li className={`custom-navigation-item custom-dropdown ${isIaqTabSelected ? "open" : ""}`} ref={iaqRef}>
            <span
              className={`custom-navigation-link ${isIaqTabSelected ? "active" : ""}`}
              onClick={handleIaqTabClick}
            >
              IAQ
            </span>
            {isIaqTabSelected && showFilters && (
              <div className="iaq-dropdown">
                <div className="filter-section">
                  <label>Region</label>
                  <select value={region} onChange={handleRegionChange}>
                    <option value="">Select Region</option>
                    <option value="mmr">MMR</option>
                    <option value="chandra">Chandrapur</option>
                  </select>
                </div>
                <div className="filter-section">
                  <label>Season</label>
                  <select value={season} onChange={handleSeasonChange}>
                    <option value="">Select Season</option>
                    <option value="s1">Pre-monsoon</option>
                    <option value="s2">Post-monsoon</option>
                  </select>
                </div>
              </div>
            )}
          </li>

          <li className="custom-navigation-item">
            <Link to="/contact" className="custom-navigation-link custom-contact-button" onClick={handleLinkClick}>
              <FontAwesomeIcon icon={faEnvelope} /> Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;