import React, { lazy, Suspense } from 'react';
import '../../css/TrendAnalysis.css';

const ParameterWise = lazy(() => import('../../components/TrendAnalysis/ParameterWise.jsx'));
const LocationWise = lazy(() => import('../TrendAnalysis/LocationWise.jsx'));

const TrendAnalysis = ({ region, season, activeTab, setActiveTab }) => {
  const renderTabContent = () => {
    switch (activeTab) {
      case 'ParameterWise':
        return <ParameterWise region={region} season={season} />;
      case 'LocationWise':
        return <LocationWise region={region} season={season} />;
      default:
        return null;
    }
  };

  return (
    <div className="trend-analysis">
      {/* Navigation Tabs */}
      <nav className="trend-tabs">
        {[
          { id: 'ParameterWise', icon: 'fas fa-chart-line', label: 'Parameter-Wise' },
          { id: 'LocationWise', icon: 'fas fa-map-marker-alt', label: 'Location-wise / Landuse-wise / Criteria-wise' },
        ].map((tab) => (
          <button
            key={tab.id}
            className={`tab-button ${activeTab === tab.id ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.id)}
          >
            <i className={tab.icon}></i> {tab.label}
          </button>
        ))}
      </nav>

      {/* Content Section with Suspense for Lazy Loading */}
      <Suspense fallback={<div>Loading...</div>}>
        {renderTabContent()}
      </Suspense>
    </div>
  );
};

export default TrendAnalysis;




/*

With Partameterwise , Locationwise and Landusewise 

*/

// import React from 'react';
// import ParameterWise from '../../components/TrendAnalysis/ParameterWise.jsx';
// import LocationWise from '../TrendAnalysis/LocationWise.jsx';
// import LanduseWise from '../TrendAnalysis/LanduseWise.jsx';
// import '../../css/TrendAnalysis.css';

// const TrendAnalysis = ({ region, season, activeTab, setActiveTab }) => {
//   const renderTabContent = () => {
//     switch (activeTab) {
//       case 'ParameterWise':
//         return <ParameterWise region={region} season={season} />;
//       case 'LocationWise':
//         return <LocationWise region={region} season={season} />;
//       case 'LanduseWise':
//         return <LanduseWise region={region} season={season} />;
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="trend-analysis">
//       {/* Header Section */}
//       {/* <div className="trend-header">
//         <h1>Trend Analysis</h1>
//         <p>Select a category to view detailed analytics.</p>
//       </div> */}

//       {/* Navigation Tabs */}
//       <nav className="trend-tabs">
//         {[
//           { id: 'ParameterWise', icon: 'fas fa-chart-line', label: 'Parameter-Wise' },
//           { id: 'LocationWise', icon: 'fas fa-map-marker-alt', label: 'Location-Wise' },
//           { id: 'LanduseWise', icon: 'fas fa-tree', label: 'Land-Use-Wise' },
//         ].map((tab) => (
//           <button
//             key={tab.id}
//             className={tab-button ${activeTab === tab.id ? 'active' : ''}}
//             onClick={() => setActiveTab(tab.id)}
//           >
//             <i className={tab.icon}></i> {tab.label}
//           </button>
//         ))}
//       </nav>

//       {/* Content Section */}
//       <div className="">{renderTabContent()}</div>
//     </div>
//   );
// };

// export default TrendAnalysis;