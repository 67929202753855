import React, { useEffect, useState } from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FiMapPin, FiPhone } from "react-icons/fi";
import "../css/FooterComponent.css"; // Ensure CSS is imported

const FooterComponent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Delay animation to prevent flickering
    setTimeout(() => setIsVisible(true), 300);
  }, []);

  return (
    <footer className={`footer ${isVisible ? "fade-in" : ""}`} id="footerr">
      <div className="footer-container">
        {/* Main Flex Container for Address and Socials */}
        <div className="footer-main">
          {/* Address Section */}
          <div className="footer-column address">
            <h4>Locate Us</h4>
            <address>
              <p>
                <FiMapPin className="footer-icon" /> 101, 202, Plot No. 26, Hem
                Opal, Ekta Society, Wakadewadi, Pune, Maharashtra 411005.
              </p>
              <p>
                <FiPhone className="footer-icon" /> 020-29996577
              </p>
            </address>
            <a
              href="https://www.google.com/maps/place/Techknowgreen+Solutions+Limited"
              target="_blank"
              rel="noopener noreferrer"
              className="direction-link"
            >
              Get Directions
            </a>
          </div>

          {/* Social Media Section */}
          <div className="footer-column follow-us">
            <h4>Follow Us</h4>
            <div className="social-icons">
              <a
                href="https://www.linkedin.com/company/techknowgreen-solutions-limited"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
                aria-label="Visit our LinkedIn profile"
              >
                <FaLinkedinIn className="social-icon" />
              </a>
              <a
                href="https://www.instagram.com/techknowgreensolutionsltd/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                <FaInstagram className="social-icon" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100090868442953"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                <FaFacebookF className="social-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="footer-bottom">
        <p>
          © {new Date().getFullYear()} Techknowgreen Solutions Limited. All
          Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default FooterComponent;
