import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import grimmsImg from '../images/instruments/grimms1.jpg';
import handysamplerImg from '../images/instruments/handysampler1.jpg';
import minivolsamplerImg from '../images/instruments/minivolsampler1.jpg';
import antliaImg from '../images/instruments/antlia.jpeg';

import peakflowImg from '../images/instruments/peakflow.jpeg';

import testoImg from '../images/instruments/testo1.jpg';
import tigerxtImg from '../images/instruments/tigerxt1.jpg';
import AOS from 'aos'; // Import AOS for scroll animations
import 'aos/dist/aos.css'; // AOS styles
import '../css/Instruments.css'; // Import the custom CSS

const Instruments = () => {
  const [modalImage, setModalImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const instrumentsData = [
    {
      name: 'Grimm 11 D',
      img: grimmsImg,
      description: 'Functionality: Light scattering at single particles.Unique measurement range in one device: Inhalable, thoracic, respirable, PM10, PM2.5 and PM1, TSP, PM10, PM4, PM2.5, PM1, PM coarse and total counts. 0 μg/m³ to 100 mg/m³.Output of 31-PM size channels simultaneously.Precise and compact.Real-time measurements.Portable use up to 10 hours.This device is used worldwide for research studies.',
    },
    {
      name: 'Handy Sampler',
      img: handysamplerImg,
      description: 'Parameters: SO2, NOX, Formaldehyde. Uses Impinger or absorption solutions for precise sample collection. Suitable for laboratory analyses. Flow rate: 0.3 to 3 L/min. Rechargeable battery supports extended field operation.',
    },
    {
      name: 'Air Metrics, Minivol Sampler',
      img: minivolsamplerImg,
      description: 'Conventional technique used in this study for data validation of sensor based sampler Gravimetric analyser PM2.5, PM10 Sampling Active Flow Control Range: 4-10 L/min Battery run time more than 24 hours Flow rate: 5 L/min (low-volume sampling method) ',
    },
    {
      name: 'Testo 400 - Universal IAQ Instrument',
      img: testoImg,
      description: 'HVAC grid measurement as per EN ISO 12599 and ASHRAE 111, PMV/PPD and turbulence as per EN ISO 7730 and ASHRAE 55 Measures, analyzes and documents all IAQ and comfort level parameters: Flow velocity, temperature, humidity, pressure, illuminance, radiant heat, turbulence, CO2 and CO comprehensive selection of IAQ probes Range: CO - 0 to 500ppm; CO2 - 0 to 10000ppm; wind velocity - 0 to 30m/s; temperature -100 to +400°C;  RH - 0 to 100% Digital CO2 probe with fixed cable connection Hot wire probe for precise airflow measurement Large, clear display and intuitive interface. Designed for indoor air monitoring',
    },
    {
      name: 'Tiger XT',
      img: tigerxtImg,
      description: 'Photo-ionisation detection (PID) detecting gases down to 1 part per billion (ppb) up to 20,000 part per million (ppm). Can detect over 750 VOCs and toxic compounds.Fastest response time of just two seconds.Widely used due to high sensitivity.',
    },
    {
      name: 'Antlia Pro',
      img: antliaImg,
      description: 'Antlia Pro is a PC-operated, non-invasive Forced Oscillation Technique (FOT) system designed for clinically evaluating the respiratory system under tidal breathing conditions, making it highly accurate, versatile, and efficient for pulmonologists. It is ideal for assessing pulmonary diseases in patients of all ages, including the weak, elderly, and young, as it requires only passive cooperation without forced expiratory maneuvers.',
    },
    {
      name: ' Peak Flow Meter',
      img: peakflowImg,
      description: 'A Peak Flow Meter is a handheld device used to measure the peak expiratory flow rate (PEFR), which indicates how well air moves out of the lungs. It is commonly used for monitoring respiratory conditions like asthma and COPD.',
    },
  ];

  // Initialize AOS animations
  useEffect(() => {
    AOS.init({ duration: 1000, once: false }); // Set duration and apply animation once
  }, []);

  // Handle Back Button click
  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  // Open modal for image preview
  const handleImageClick = (img) => {
    setModalImage(img);
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="instruments-section">
      <h3 className="section-title">Instruments</h3>

      {/* Map through each instrument and render it */}
      <div className="instrument-container">
        {instrumentsData.map((instrument, index) => {
          const isEven = index % 2 === 0;
          return (
            <div
              className={`instrument ${isEven ? 'reverse' : ''}`}
              key={instrument.name}
              data-aos={isEven ? "fade-left" : "fade-right"} // Scroll animation
            >
              <img
                src={instrument.img}
                alt={instrument.name}
                className="instrument-image"
                onClick={() => handleImageClick(instrument.img)}
              />
              <div className="instrument-text">
                <h5>{instrument.name}</h5>
                <p>{instrument.description}</p>
              </div>
            </div>
          );
        })}
        <button className="baccck-button" onClick={handleBackClick}>Back</button>
      </div>

      {/* Modal for Image Preview */}
      {isModalOpen && (
        <div className={`modal ${isModalOpen ? 'open' : ''}`} onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closeModal}>&times;</span>
            <img src={modalImage} alt="Modal Preview" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Instruments;
