import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import '../../css/common.css'; // Assuming this exists for styling
import { Margin } from '@mui/icons-material'; // Unused in your code, removed unless needed

const CinemaHallObservationForm = () => {
  const [observation, setObservation] = useState({
    observerName: '',
    observationDate: '',
    participantId: '',
    cinemaHallType: '',
    cinemaHallAddress: '',
    buildingAge: '',
    floorLevel: '',
    otherFloorLevel: '',
    totalArea: '',
    seatingCapacity: '',
    seatingType: '',
    otherSeatingType: '',
    floorType: '',
    occupantsDensity: '',
    cookingFacilities: '',
    smokingAreas: '',
    furnishingsDecor: '',
    otherFurnishingsDecor: '',
    housekeepingFrequency: '',
    cleaningMaterials: '',
    otherCleaningMaterials: '',
    scentsUsed: '',
    otherScentsUsed: '',
    scentSprayingFrequency: '',
    otherScentSprayingFrequency: '',
    hvacMaintenanceFrequency: '',
    numOfVents: '',
    exhaustFanInletVelocity: '',
    exhaustFanOutletVelocity: '',
    pestControlFrequency: '',
    otherPestControlFrequency: '',
    airFilterReplacementFrequency: '',
    otherAirFilterReplacementFrequency: '',
    airPurifiers: '',
    locationId: '',
    remarks: ''
  });

  const [loading, setLoading] = useState(false); // Loading state for submission
  const [errors, setErrors] = useState({}); // Error state for validation

  // Generic change handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setObservation(prevState => ({
      ...prevState,
      [name]: value
    }));
    // Clear error for this field on change
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  // Validation function
  const validateForm = () => {
    const newErrors = {};
    const requiredFields = [
      'observerName', 'observationDate', 'cinemaHallType', 'cinemaHallAddress',
      'buildingAge', 'floorLevel', 'totalArea', 'seatingCapacity', 'seatingType',
      'occupantsDensity', 'cookingFacilities', 'smokingAreas', 'furnishingsDecor',
      'housekeepingFrequency', 'cleaningMaterials', 'scentsUsed', 'scentSprayingFrequency',
      'hvacMaintenanceFrequency', 'numOfVents', 'exhaustFanInletVelocity',
      'exhaustFanOutletVelocity', 'pestControlFrequency', 'airFilterReplacementFrequency',
      'airPurifiers', 'locationId', 'remarks'
    ];

    requiredFields.forEach(field => {
      if (!observation[field]) {
        newErrors[field] = `${field.replace(/([A-Z])/g, ' $1').trim()} is required`;
      }
    });

    // Validate "Other" fields if selected
    if (observation.floorLevel === 'Other' && !observation.otherFloorLevel) {
      newErrors.otherFloorLevel = 'Please specify other floor level';
    }
    if (observation.seatingType === 'Other' && !observation.otherSeatingType) {
      newErrors.otherSeatingType = 'Please specify other seating type';
    }
    if (observation.furnishingsDecor === 'Other' && !observation.otherFurnishingsDecor) {
      newErrors.otherFurnishingsDecor = 'Please specify other furnishings and decor';
    }
    if (observation.cleaningMaterials === 'Others' && !observation.otherCleaningMaterials) {
      newErrors.otherCleaningMaterials = 'Please specify other cleaning materials';
    }
    if (observation.scentsUsed === 'Others' && !observation.otherScentsUsed) {
      newErrors.otherScentsUsed = 'Please specify other scents used';
    }
    if (observation.scentSprayingFrequency === 'Others' && !observation.otherScentSprayingFrequency) {
      newErrors.otherScentSprayingFrequency = 'Please specify other scent spraying frequency';
    }
    if (observation.pestControlFrequency === 'Others' && !observation.otherPestControlFrequency) {
      newErrors.otherPestControlFrequency = 'Please specify other pest control frequency';
    }
    if (observation.airFilterReplacementFrequency === 'Other' && !observation.otherAirFilterReplacementFrequency) {
      newErrors.otherAirFilterReplacementFrequency = 'Please specify other air filter replacement frequency';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error('Please fill all required fields correctly.');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        'http://89.116.20.162:8081/api/cinema-hall-observations/add',
        observation
      );
      console.log('Submission successful:', response.data);
      toast.success('Observation submitted successfully!');

      // Reset form
      setObservation({
        observerName: '',
        observationDate: '',
        participantId: '',
        cinemaHallType: '',
        cinemaHallAddress: '',
        buildingAge: '',
        floorLevel: '',
        otherFloorLevel: '',
        totalArea: '',
        seatingCapacity: '',
        seatingType: '',
        otherSeatingType: '',
        floorType: '',
        occupantsDensity: '',
        cookingFacilities: '',
        smokingAreas: '',
        furnishingsDecor: '',
        otherFurnishingsDecor: '',
        housekeepingFrequency: '',
        cleaningMaterials: '',
        otherCleaningMaterials: '',
        scentsUsed: '',
        otherScentsUsed: '',
        scentSprayingFrequency: '',
        otherScentSprayingFrequency: '',
        hvacMaintenanceFrequency: '',
        numOfVents: '',
        exhaustFanInletVelocity: '',
        exhaustFanOutletVelocity: '',
        pestControlFrequency: '',
        otherPestControlFrequency: '',
        airFilterReplacementFrequency: '',
        otherAirFilterReplacementFrequency: '',
        airPurifiers: '',
        locationId: '',
        remarks: ''
      });
      setErrors({});
    } catch (error) {
      console.error('Error submitting observation:', error);
      toast.error(error.response?.data?.message || 'An error occurred while submitting. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Reusable component for select with "Other" option
  const SelectWithOther = ({ label, name, value, options, otherValue, otherName, onChange }) => (
    <label>
      {label}:
      <select name={name} value={value} onChange={onChange} required>
        <option value="">Select</option>
        {options.map(opt => (
          <option key={opt.value} value={opt.value}>{opt.label}</option>
        ))}
      </select>
      {value === 'Other' || value === 'Others' ? (
        <input
          type="text"
          name={otherName}
          value={otherValue}
          onChange={onChange}
          placeholder="Please specify"
          required
          className={errors[otherName] ? 'error-input' : ''}
        />
      ) : null}
      {errors[name] && <span className="error">{errors[name]}</span>}
      {errors[otherName] && <span className="error">{errors[otherName]}</span>}
    </label>
  );

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <form onSubmit={handleSubmit} className="observation-form">
        <center>
          <h1 style={{ marginTop: '80px' }}>Cinema Hall Observation</h1>
        </center>

        <label>
          Location ID:
          <input
            type="text"
            name="locationId"
            value={observation.locationId}
            onChange={handleChange}
            required
            className={errors.locationId ? 'error-input' : ''}
          />
          {errors.locationId && <span className="error">{errors.locationId}</span>}
        </label>

        <label>
          Name of Observer:
          <input
            type="text"
            name="observerName"
            value={observation.observerName}
            onChange={handleChange}
            required
            className={errors.observerName ? 'error-input' : ''}
          />
          {errors.observerName && <span className="error">{errors.observerName}</span>}
        </label>

        <label>
          Observation Date:
          <input
            type="date"
            name="observationDate"
            value={observation.observationDate}
            onChange={handleChange}
            required
            className={errors.observationDate ? 'error-input' : ''}
          />
          {errors.observationDate && <span className="error">{errors.observationDate}</span>}
        </label>

        <SelectWithOther
          label="Type of Cinema Hall"
          name="cinemaHallType"
          value={observation.cinemaHallType}
          options={[
            { value: 'Single screen', label: 'Single screen' },
            { value: 'Standalone Multiplex', label: 'Standalone Multiplex' },
            { value: 'Multiplex in a mall', label: 'Multiplex in a mall' }
          ]}
          onChange={handleChange}
        />

        <label>
          Address of the Cinema Hall:
          <input
            type="text"
            name="cinemaHallAddress"
            value={observation.cinemaHallAddress}
            onChange={handleChange}
            required
            className={errors.cinemaHallAddress ? 'error-input' : ''}
          />
          {errors.cinemaHallAddress && <span className="error">{errors.cinemaHallAddress}</span>}
        </label>

        <SelectWithOther
          label="Building Age in Years"
          name="buildingAge"
          value={observation.buildingAge}
          options={[
            { value: '<5', label: '<5' },
            { value: '5 to 10', label: '5 to 10' },
            { value: '10 to 20', label: '10 to 20' },
            { value: '20 to 30', label: '20 to 30' },
            { value: '>30', label: '>30' }
          ]}
          onChange={handleChange}
        />

        <SelectWithOther
          label="Location of the hall (Floor level)"
          name="floorLevel"
          value={observation.floorLevel}
          options={[
            { value: '1', label: '1' },
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: 'Other', label: 'Other' }
          ]}
          otherValue={observation.otherFloorLevel}
          otherName="otherFloorLevel"
          onChange={handleChange}
        />

        <SelectWithOther
          label="Total Area of Hall"
          name="totalArea"
          value={observation.totalArea}
          options={[
            { value: '<50m2', label: '<50m2' },
            { value: '100-200m2', label: '100-200m2' },
            { value: '200-400m2', label: '200-400m2' },
            { value: '>400m2', label: '>400m2' }
          ]}
          onChange={handleChange}
        />

        <SelectWithOther
          label="Seating Capacity"
          name="seatingCapacity"
          value={observation.seatingCapacity}
          options={[
            { value: '<100', label: '<100' },
            { value: '100-300', label: '100-300' },
            { value: '>300', label: '>300' }
          ]}
          onChange={handleChange}
        />

        <SelectWithOther
          label="Type of seating"
          name="seatingType"
          value={observation.seatingType}
          options={[
            { value: 'Standard seats', label: 'Standard seats' },
            { value: 'Recliner seats', label: 'Recliner seats' },
            { value: 'Other', label: 'Other' }
          ]}
          otherValue={observation.otherSeatingType}
          otherName="otherSeatingType"
          onChange={handleChange}
        />

        <SelectWithOther
          label="Density of Occupants"
          name="occupantsDensity"
          value={observation.occupantsDensity}
          options={[
            { value: 'Sparse', label: 'Sparse' },
            { value: 'Moderate', label: 'Moderate' },
            { value: 'Crowded', label: 'Crowded' }
          ]}
          onChange={handleChange}
        />

        <SelectWithOther
          label="Cooking Facilities within the Cinema Hall"
          name="cookingFacilities"
          value={observation.cookingFacilities}
          options={[
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' }
          ]}
          onChange={handleChange}
        />

        <SelectWithOther
          label="Presence of Smoking or Vaping Areas"
          name="smokingAreas"
          value={observation.smokingAreas}
          options={[
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' }
          ]}
          onChange={handleChange}
        />

        <SelectWithOther
          label="What types of furnishings and decor are present within the cinema hall?"
          name="furnishingsDecor"
          value={observation.furnishingsDecor}
          options={[
            { value: 'Upholstered furniture', label: 'Upholstered furniture' },
            { value: 'Carpeting or rugs', label: 'Carpeting or rugs' },
            { value: 'Curtains or drapes', label: 'Curtains or drapes' },
            { value: 'Wall coverings', label: 'Wall coverings (e.g., wallpaper)' },
            { value: 'Other', label: 'Other' }
          ]}
          otherValue={observation.otherFurnishingsDecor}
          otherName="otherFurnishingsDecor"
          onChange={handleChange}
        />

        <SelectWithOther
          label="Frequency of Housekeeping Activities per Day"
          name="housekeepingFrequency"
          value={observation.housekeepingFrequency}
          options={[
            { value: '1-2', label: '1-2' },
            { value: '2-4', label: '2-4' },
            { value: '4-6', label: '4-6' },
            { value: '6-8', label: '6-8' },
            { value: '>10', label: '>10' }
          ]}
          onChange={handleChange}
        />

        <SelectWithOther
          label="Chemical-Based Cleaning Materials Used"
          name="cleaningMaterials"
          value={observation.cleaningMaterials}
          options={[
            { value: 'Disinfectants', label: 'Disinfectants' },
            { value: 'Floor cleaners', label: 'Floor cleaners' },
            { value: 'Others', label: 'Others' }
          ]}
          otherValue={observation.otherCleaningMaterials}
          otherName="otherCleaningMaterials"
          onChange={handleChange}
        />

        <SelectWithOther
          label="Scents Used in the Cinema Hall"
          name="scentsUsed"
          value={observation.scentsUsed}
          options={[
            { value: 'Perfumes', label: 'Perfumes' },
            { value: 'Room fresheners', label: 'Room fresheners' },
            { value: 'Others', label: 'Others' }
          ]}
          otherValue={observation.otherScentsUsed}
          otherName="otherScentsUsed"
          onChange={handleChange}
        />

        <SelectWithOther
          label="Frequency of Spraying the Scents"
          name="scentSprayingFrequency"
          value={observation.scentSprayingFrequency}
          options={[
            { value: '<30 minutes', label: '<30 minutes' },
            { value: '1-2 hours', label: '1-2 hours' },
            { value: '>2 hours', label: '>2 hours' },
            { value: 'Others', label: 'Others' }
          ]}
          otherValue={observation.otherScentSprayingFrequency}
          otherName="otherScentSprayingFrequency"
          onChange={handleChange}
        />

        <SelectWithOther
          label="How Frequently is the HVAC System Inspected and Maintained"
          name="hvacMaintenanceFrequency"
          value={observation.hvacMaintenanceFrequency}
          options={[
            { value: 'Monthly', label: 'Monthly' },
            { value: 'Quarterly', label: 'Quarterly' },
            { value: 'Annually', label: 'Annually' },
            { value: 'Irregularly', label: 'Irregularly' }
          ]}
          onChange={handleChange}
        />

        <SelectWithOther
          label="Number of Vents"
          name="numOfVents"
          value={observation.numOfVents}
          options={[
            { value: '10-20', label: '10-20' },
            { value: '20-30', label: '20-30' },
            { value: '30-40', label: '30-40' },
            { value: '40-50', label: '40-50' },
            { value: '>50', label: '>50' }
          ]}
          onChange={handleChange}
        />

        <label>
          Exhaust Fan Air Velocities Inlet:
          <input
            type="text"
            name="exhaustFanInletVelocity"
            value={observation.exhaustFanInletVelocity}
            onChange={handleChange}
            required
            className={errors.exhaustFanInletVelocity ? 'error-input' : ''}
          />
          {errors.exhaustFanInletVelocity && <span className="error">{errors.exhaustFanInletVelocity}</span>}
        </label>

        <label>
          Exhaust Fan Air Velocities Outlet:
          <input
            type="text"
            name="exhaustFanOutletVelocity"
            value={observation.exhaustFanOutletVelocity}
            onChange={handleChange}
            required
            className={errors.exhaustFanOutletVelocity ? 'error-input' : ''}
          />
          {errors.exhaustFanOutletVelocity && <span className="error">{errors.exhaustFanOutletVelocity}</span>}
        </label>

        <SelectWithOther
          label="Pest Control Frequency"
          name="pestControlFrequency"
          value={observation.pestControlFrequency}
          options={[
            { value: 'Recently', label: 'Recently' },
            { value: '<1 year', label: '<1 year' },
            { value: '1-2 years', label: '1-2 years' },
            { value: 'Others', label: 'Others' }
          ]}
          otherValue={observation.otherPestControlFrequency}
          otherName="otherPestControlFrequency"
          onChange={handleChange}
        />

        <SelectWithOther
          label="How Often are the Air Filters in the HVAC System Replaced"
          name="airFilterReplacementFrequency"
          value={observation.airFilterReplacementFrequency}
          options={[
            { value: 'Every month', label: 'Every month' },
            { value: 'Every three months', label: 'Every three months' },
            { value: 'Every six months', label: 'Every six months' },
            { value: 'Annually', label: 'Annually' },
            { value: 'Other', label: 'Other' }
          ]}
          otherValue={observation.otherAirFilterReplacementFrequency}
          otherName="otherAirFilterReplacementFrequency"
          onChange={handleChange}
        />

        <SelectWithOther
          label="Any Type of Air Purifiers or Filters Used"
          name="airPurifiers"
          value={observation.airPurifiers}
          options={[
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' }
          ]}
          onChange={handleChange}
        />

        <label>
          Remarks:
          <input
            type="text"
            name="remarks"
            value={observation.remarks}
            onChange={handleChange}
            required
            className={errors.remarks ? 'error-input' : ''}
          />
          {errors.remarks && <span className="error">{errors.remarks}</span>}
        </label>

        <button type="submit" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
    </>
  );
};

export default CinemaHallObservationForm;