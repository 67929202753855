import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import homeImage from '../images/awareness/Home.png';
import twoImage from '../images/awareness/2.png';
import threeImage from '../images/awareness/3.png';
import fourImage from '../images/awareness/4.png';
import "../css/Awareness.css";

const Awareness = () => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  // Array of all images
  const images = [
    homeImage,
    twoImage,
    threeImage,
    fourImage
  ];

  useEffect(() => {
    // Trigger entry animation
    setTimeout(() => setLoaded(true), 100);
  }, []);



  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className={`awareness-container ${loaded ? 'loaded' : ''}`}>
    

      {/* Desktop Grid View */}
      <div className="image-grid">
        {images.map((image, index) => (
          <div 
            key={index} 
            className="image-card"
            style={{ transitionDelay: `${index * 0.1}s` }}
          >
            <img 
              src={image} 
              alt={`Awareness content ${index + 1}`} 
              className="responsive-image"
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Awareness;