import React from "react";
import { CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import styled from "styled-components";
import "../css/InfoCards.css";
import { FaBlog, FaChartBar, FaWrench, FaRegLightbulb } from "react-icons/fa"; // Icons for cards

/* Import images for each card */
import galleryImage from "../images/cardimages/gallery.jpg";
import instrumentsImage from "../images/cardimages/allinstruments.jpg";
import overviewImage from "../images/cardimages/chart.jpg";
import awarenessImage from "../images/cardimages/awarness.jpg";

// Card Data
const cardData = [
  {
    title: "Blogs",
    description: "Explore our collection.",
    image: galleryImage,
    link: "/blog",
    icon: <FaBlog />,
  },
  {
    title: "Instruments",
    description: "Discover instruments.",
    image: instrumentsImage,
    link: "/instruments",
    icon: <FaWrench />,
  },
  {
    title: "Overview",
    description: "Project summary",
    image: overviewImage,
    link: "/overview",
    icon: <FaChartBar />,
  },
  {
    title: "Awareness",
    description: "Join our initiatives.",
    image: awarenessImage,
    link: "/awareness",
    icon: <FaRegLightbulb />,
  },
];

// Styled Components for Custom Design
const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  padding: 60px 20px;
  // background: linear-gradient(135deg, rgba(255, 89, 77, 0.6), rgba(0, 255, 255, 0.4));
  position: relative;
  z-index: 0;
  animation: backgroundScroll 15s ease-in-out infinite;
  background-size: 300% 300%;
`;

const StyledCard = styled(motion.div)`
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.85);
  transition: transform 0.3s ease, box-shadow 0.4s ease, filter 0.4s ease;
  border: 2px solid transparent;
  &:hover {
    transform: scale(1.1) rotate(5deg);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
    filter: brightness(1.2);
    border: 2px solid #00c4e8;
  }
`;

const CardImageWrapper = styled.div`
  overflow: hidden;
  position: relative;
  height: 250px;
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease;
  ${StyledCard}:hover & {
    transform: scale(1.1);
  }
`;

const CardOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8));
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
`;

const Title = styled(Typography)`
  font-size: 1.8rem;
  font-weight: 700;
`;

const Description = styled(Typography)`
  font-size: 1.1rem;
  margin-top: 8px;
  opacity: 0.8;
`;

const IconWrapper = styled.div`
  font-size: 3rem;
  margin-bottom: 10px;
  color: #00c4e8;
`;


const AnimatedLink = styled(Link)`
  text-decoration: none;
`;

const InfoCards = () => {
  const handleNavigation = () => {
    window.scrollTo(0, 0); // Scroll to top
  };

  return (
    <CardsContainer>
      {cardData.map((card, index) => (
        <AnimatedLink to={card.link} key={index} onClick={handleNavigation}>
          <StyledCard
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, delay: index * 0.2 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <CardImageWrapper>
              <CardImage src={card.image} alt={card.title} />
              <CardOverlay>
                <IconWrapper>{card.icon}</IconWrapper>
                <Title variant="h6">{card.title}</Title>
                <Description variant="body2">{card.description}</Description>
              </CardOverlay>
            </CardImageWrapper>
          </StyledCard>
        </AnimatedLink>
      ))}
    </CardsContainer>
  );
};

export default InfoCards;

