import React, { useState, useEffect, useRef } from "react";
import "../css/HeroSection.css";
import video from "../images/video.mp4";

const HeroSection = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            videoRef.current.play();
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the video is visible
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <div className="hero-container">
      <div className={`hero-overlay ${isScrolled ? "zoom-out" : ""}`}>
        {/* <h1>SAFE IAQ</h1>
        <p>Clean Air, Healthy Life</p> */}
      </div>
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        preload="auto"
        className={`hero-video ${isScrolled ? "fade-in" : ""}`}
      >
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
};

export default HeroSection;